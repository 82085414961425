import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-employee-card',
    templateUrl: './employee-card.component.html',
    styleUrls: ['./employee-card.component.scss']
})

export class EmployeeCardComponent{
    @Input() name: string;
    @Input() role?: string;
    @Input() description: string;
    @Input() theme: string;
    @Input() linkedinLink?: string;
    @Input() pictureSrc: string;
}
