import { Injectable } from "@angular/core";
import galleryJson from '../../assets/img/gallery_paths.json'


@Injectable({
    providedIn: 'root'
})
export class GalleryInfoService {

    getImagePaths(galleryPath: string) {
        let result = []
        galleryJson[galleryPath].forEach(imgName => {
            result.push("./assets/img/" + galleryPath + "/" + imgName)
        });
        return result;
    }
}