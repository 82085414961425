import { Component, OnInit } from '@angular/core';
import { IsNgoService } from 'app/services/is-ngo-service';

@Component({
    selector: 'app-ngo-about',
    templateUrl: './ngo-about.component.html',
    styleUrls: ['./ngo-about.component.scss']
})

export class NgoAboutComponent implements OnInit {

    constructor(private _isNgoChecker: IsNgoService) 
    {
        this._isNgoChecker.emitChange(true);
    }

    ngOnInit() {
    }

}
