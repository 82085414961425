import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'accordion',
  templateUrl: './accordion.component.html'
})
export class AccordionComponent {
language: string;
@Input() contents: []

constructor(
  public translate: TranslateService){ }
}