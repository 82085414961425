import { Component, OnInit } from '@angular/core';
import { IsNgoService } from 'app/services/is-ngo-service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(private _isNgoChecker: IsNgoService) 
  {
    this._isNgoChecker.emitChange(false);
  }

  ngOnInit(): void {
  }

}
