import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'thumbnail',
    templateUrl: './thumbnail.component.html',
    styleUrls: ['./thumbnail.component.scss']
})

export class ThumbnailComponent {
    @Input() title?: string;
    @Input() description?: string;
    @Input() pictureSrc?: string;
    @Input() hasGallery?: boolean;

    constructor(private router: Router) { }

    redirect(path): void {
        this.router.navigateByUrl(path).then(() => {});
      }
}
