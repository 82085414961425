export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyBigYrb7dSyUb9TxurGn6cbSn6HTOdOWTQ',
    authDomain: 'globalmind-prod.firebaseapp.com',
    projectId: 'globalmind-prod',
    storageBucket: 'globalmind-prod.appspot.com',
    messagingSenderId: '141137078287',
    appId: '1:141137078287:web:81e9b7dc260c81b1e59b7a',
    measurementId: 'G-CRP1S2NETF'
  },
};
