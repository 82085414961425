import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { RouterModule } from '@angular/router';
import { ComponentsComponent } from './components.component';
import { EmployeeCardComponent } from './employee-card/employee-card.component';
import { GallerySliderComponent } from './gallery-slider/gallery-slider.component';
import { AccordionComponent } from './accordion/accordion.component';
import { TranslateModule } from '@ngx-translate/core';
import { ThumbnailComponent } from './thumbnail/thumbnail.component';
import { ThumbnailGridComponent } from './thumbnail-grid/thumbnail-grid.component';
import { GradientListComponent } from './gradient-list/gradient-list.component';
import { VideoCarouselComponent } from './video-carousel/video-carousel.component';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {YouTubePlayerModule} from '@angular/youtube-player';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NouisliderModule,
        RouterModule,
        JwBootstrapSwitchNg2Module,
        TranslateModule,
        CarouselModule,
        YouTubePlayerModule,
        BrowserAnimationsModule
    ],
    declarations: [
        ComponentsComponent,
        EmployeeCardComponent,
        AccordionComponent,
        GallerySliderComponent,
        ThumbnailComponent,
        ThumbnailGridComponent,
        GradientListComponent,
        VideoCarouselComponent
    ],
    exports: [
        ComponentsComponent,
        EmployeeCardComponent,
        AccordionComponent,
        GallerySliderComponent,
        ThumbnailComponent,
        ThumbnailGridComponent,
        GradientListComponent,
        VideoCarouselComponent
    ]
})
export class ComponentsModule { }
