import { Component, Input } from '@angular/core';

@Component({
    selector: 'gradient-list',
    templateUrl: './gradient-list.component.html',
    styleUrls: ['./gradient-list.component.scss']
})

export class GradientListComponent {
    @Input() title: string;
    @Input() items;
}
