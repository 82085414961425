import { Component, Input } from '@angular/core';

@Component({
    selector: 'thumbnail-grid',
    templateUrl: './thumbnail-grid.component.html',
    styleUrls: ['./thumbnail-grid.component.scss']
})

export class ThumbnailGridComponent {
    @Input() title: string
    @Input() contents: []
}
