import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { AboutComponent } from './pages/about/about.component';
import { HomeComponent } from './pages/home/home.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ContactComponent } from './pages/contact/contact.component';
import { NgoComponent } from './pages/ngo/ngo.component';
import { ProductsComponent } from './pages/products/products.component';
import { InvestorsComponent } from './pages/investors/investors.component';
import { NgoTripsComponent } from './pages/ngo-trips/ngo-trips.component';
import { NgoAboutComponent } from './pages/ngo-about/ngo-about.component';
import { NgoErasmusComponent } from './pages/ngo-erasmus/ngo-erasmus.component';
import { NgoClassesComponent } from './pages/ngo-classes/ngo-classes.component';

const routes: Routes =[
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home',      component: HomeComponent },
    { path: 'about',     component: AboutComponent },
    { path: 'ngo-about',     component: NgoAboutComponent },
    { path: 'contact',   component: ContactComponent },
    { path: 'privacy',   component: PrivacyComponent },
    { path: 'ngo',   component: NgoComponent },
    { path: 'ngo-trips',   component: NgoTripsComponent },
    { path: 'ngo-erasmus',   component: NgoErasmusComponent },
    { path: 'ngo-classes',   component: NgoClassesComponent },
    { path: 'products',   component: ProductsComponent },
    { path: 'products',   component: ProductsComponent },
    { path: 'investors',   component: InvestorsComponent },
    { path: 'samples',   component: ComponentsComponent }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      scrollPositionRestoration: 'top', 
      useHash: false,
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
