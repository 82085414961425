import {Component} from '@angular/core';
import { GalleryInfoService } from 'app/services/gallery-info-service';
import {IsNgoService} from 'app/services/is-ngo-service';
import { GalleryInfo } from '../../models/gallery-info';

@Component({
    selector: 'ngo-classes',
    templateUrl: './ngo-classes.component.html',
    styleUrls: ['./ngo-classes.component.scss']
})
export class NgoClassesComponent {
    titles: string[];
    files: GalleryInfo[];

    constructor(
        private _isNgoChecker: IsNgoService,
        galleryService: GalleryInfoService) {
        this._isNgoChecker.emitChange(true);
        this.collectFilenames(galleryService);
    }

    collectFilenames(gs: GalleryInfoService) {
        var dirNames = ["local_1", "local_2"]
        this.files = []
        dirNames.forEach(element => {
            var title = element.split('_')[0];
            this.files.push({title: title, items: gs.getImagePaths("ngo/local-classes-gallery/" + element)});
        });
    }
}
