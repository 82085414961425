import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GalleryInfoService } from 'app/services/gallery-info-service';
import { IsNgoService } from 'app/services/is-ngo-service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent {
  public isItalianVisible : boolean;
  listItems: any;
  title: string;
  files: any;

  constructor(
    private router: Router,
    public galleryService: GalleryInfoService,
    public translate: TranslateService,
    private _isNgoChecker: IsNgoService) {
      this._isNgoChecker.emitChange(false);
      this.checkLanguage();
      this.translate.onLangChange.subscribe(() => {this.checkLanguage();})
      
    this.listItems = [
      "products.listItem1",
      "products.listItem2",
      "products.listItem3",
      "products.listItem4"
    ]
    this.files = []
    this.files["food"] = galleryService.getImagePaths("products/food");
    this.files["fashion"] = galleryService.getImagePaths("products/fashion");
    this.files["traveling"] = galleryService.getImagePaths("products/tourism-in-calabria");
  }

  ngOnInit() { }

  private checkLanguage(){
    this.isItalianVisible = this.translate.currentLang == "it";
  }

  redirect(path): void {
    this.router.navigateByUrl(path);
  }
}