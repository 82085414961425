import { Component } from '@angular/core';
import { IsNgoService } from 'app/services/is-ngo-service';

@Component({
  selector: 'app-investors',
  templateUrl: './investors.component.html',
  styleUrls: ['./investors.component.scss']
})
export class InvestorsComponent {
  listItems: any;

  constructor(private _isNgoChecker: IsNgoService) 
    {
        this._isNgoChecker.emitChange(false);
        this.listItems = [
          "investors.listItem1",
          "investors.listItem2",
          "investors.listItem3",
          "investors.listItem4",
          "investors.listItem5",
          "investors.listItem6"
        ]
    }
}
