import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'video-carousel',
    templateUrl: './video-carousel.component.html',
    styleUrls: ['./video-carousel.component.scss']
})
export class VideoCarouselComponent implements OnInit {
    @Input() videosIDs: any;
    videos: any;
    isVideoOn: boolean = false;
    switchedOnVideo: any;

    constructor() { }

    ngOnInit(): void {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(tag);
        this.videos = this.videosIDs;
    }

    // checking if the prev slide is playing and if so, pausing it.
    onSlide(){
        if (this.switchedOnVideo){
            this.switchedOnVideo.pauseVideo();
        }
    }

    videoStateChange(e: any) {
        this.isVideoOn = !this.isVideoOn;
        if (this.isVideoOn)
            this.switchedOnVideo = e.target;
    }
}
