import {Component} from '@angular/core';
import {GalleryInfoService} from 'app/services/gallery-info-service';
import {IsNgoService} from 'app/services/is-ngo-service';
import {GalleryInfo} from '../../models/gallery-info';

@Component({
    selector: 'ngo-erasmus',
    templateUrl: './ngo-erasmus.component.html',
    styleUrls: ['./ngo-erasmus.component.scss']
})
export class NgoErasmusComponent {
    files2021: GalleryInfo[];
    files2022: GalleryInfo[];

    constructor(
        private _isNgoChecker: IsNgoService,
        galleryService: GalleryInfoService) {
        this._isNgoChecker.emitChange(true);
        this.collectFilenames(galleryService);
    }

    collectFilenames(gs: GalleryInfoService) {
        const dirNames2021 = ['2021_1', '2021_2']
        const dirNames2022 = ['2022_1', '2022_2']
        this.files2021 = []
        dirNames2021.forEach(element => {
            const title = element.split('_')[0];
            this.files2021.push({title: title, items: gs.getImagePaths('ngo/erasmus-gallery/' + element)});
        });
        this.files2022 = []
        dirNames2022.forEach(element => {
            const title = element.split('_')[0];
            this.files2022.push({title: title, items: gs.getImagePaths('ngo/erasmus-gallery/' + element)});
        });
    }
}
