import {Component} from '@angular/core';
import {GalleryInfoService} from 'app/services/gallery-info-service';
import {IsNgoService} from 'app/services/is-ngo-service';
import {GalleryInfo} from '../../models/gallery-info';
import {AssetsConfig} from '../../configs/assets.config';

@Component({
    selector: 'app-ngo-trips',
    templateUrl: './ngo-trips.component.html',
    styleUrls: ['./ngo-trips.component.scss']
})
export class NgoTripsComponent {
    files2017: GalleryInfo[];
    files2018: GalleryInfo[];
    files2019: GalleryInfo[];
    filesAmerica: GalleryInfo[];
    filesAmerica2022: GalleryInfo[];
    video_1_asset: any;
    video_2_asset: any;
    video_3_asset: any;

    constructor(
        private _isNgoChecker: IsNgoService,
        galleryService: GalleryInfoService) {
        this._isNgoChecker.emitChange(true);
        this.collectFilenames(galleryService);
        this.video_1_asset = AssetsConfig.video_2017;
        this.video_2_asset = AssetsConfig.video_2018;
        this.video_3_asset = AssetsConfig.video_2019;

        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(tag);
    }

    collectFilenames(gs: GalleryInfoService) {
        const dirNames2017 = ['2017_1', '2017_2'];
        const dirNames2018 = ['2018_1', '2018_2', '2018_3', '2018_4', '2018_5', '2018_6']
        const dirNames2019 = ['2019_1', '2019_2', '2019_3']
        const dirNamesAmerica = ['america_1', 'america_2']
        const dirNamesAmerica2022 = ['2022_america_1', '2022_america_2']
        this.files2017 = []
        dirNames2017.forEach(element => {
            this.files2017.push({title: '', items: gs.getImagePaths('ngo/trips-gallery/' + element)});
        });
        this.files2018 = []
        dirNames2018.forEach(element => {
            this.files2018.push({title: '', items: gs.getImagePaths('ngo/trips-gallery/' + element)});
        });
        this.files2019 = []
        dirNames2019.forEach(element => {
            this.files2019.push({title: '', items: gs.getImagePaths('ngo/trips-gallery/' + element)});
        });
        this.filesAmerica = []
        dirNamesAmerica.forEach(element => {
            this.filesAmerica.push({title: '', items: gs.getImagePaths('ngo/trips-gallery/' + element)});
        });
        this.filesAmerica2022 = []
        dirNamesAmerica2022.forEach(element => {
            this.filesAmerica2022.push({title: '', items: gs.getImagePaths('ngo/trips-gallery/' + element)});
        });
    }
}
