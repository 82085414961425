export const AssetsConfig: any = {
    languages: ['en', 'it', 'es', 'pl'],
    video_2017: [
        'gOxvIRGu4tk',
        'gQRr8U-1_Js',
        'qzUo2DooZpE',
        'p57w3rsuUWU',
        'kwNy02rcbgk',
        'd-wehwAZ5lg',
        'r8Bg9_0R1Ro'
    ],
    video_2018: [
        '2Pr_r5dS-l0',
        '_felfyxX54U',
        'v0R1zDBdnfc',
        'pd08V-JQR0s',
        'HKWUjLtlL1k',
        'UY8kQq3S3Ac',
        'qBJ2DoHK5bo',
        'X6o71FOqK9A',
        'oG1yR4hSIEI',
        'RtM4csOyDNk',
        'QWtzen8ilig',
        '5kp4NCW_cvo',
        'BZb3wpqNbUE',
        'p5sYum5IDf4',
        'F6yATn3XFFE',
        'To1XZ0Wr6dQ',
        'yALt07MkPC4',
        'YbgndnkYkfo',
        '3IMVuC-ar-M',
        '_2V9VorYUF8',
        'EJ5ejh91Bng',
        'w23h6BhukwQ',
        'uT4GZjWCFOc',
        'dKl_kl_onqE',
        '_MKGl50iOqg',
        'yCOBgE4Y40I',
        'CRRDk_kgx_E]'
    ],
    video_2019: [
        'n4_DftVEslA',
        '2bF2Fh77qd0',
        'zTZXHwulfWc',
        'xQhCqssfrSM',
        'Jy7eY2wXLKY',
        'H60oQwDPxrw',
        'J1J0UlSeycI',
        'K2ZjMNX_3JU',
        'Cy2pAXFeYIU',
        'nJwB-W1QAfo',
        'AHT51eBoxzM',
        '3Dkl6RE2kKE',
        '-tfCAbdEK80',
        'LDVSl1kLbJA',
        'dISgLOqw7IU',
        'j-J88tWa_Qs',
        'qpKGB34M-Pc',
        '78s7bOU2o5E',
        'zs4fGXSMt4Q',
        'DfLhOdWAXLg'
    ]
}
