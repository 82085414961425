import { Component, OnInit } from '@angular/core';
import { IsNgoService } from 'app/services/is-ngo-service';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})

export class AboutComponent implements OnInit {

    constructor(private _isNgoChecker: IsNgoService) 
    {
        this._isNgoChecker.emitChange(false);
    }

    ngOnInit() {
    }

}
