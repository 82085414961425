import {Component, ElementRef, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AssetsConfig} from '../../configs/assets.config';
import {IsNgoService} from 'app/services/is-ngo-service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  host: { '(document:click)': 'onClick($event)'}
})
export class NavbarComponent implements OnInit {
  private toggleButton: any;
  public sidebarVisible: boolean;
  public currentLanguage: string;
  public facebookHref: string;
  public instagramHref: string;
  public aboutUsHref: string;
  public logoSrc: string;
  public isNgoPageOn = false;


  constructor(
    public location: Location,
    private element: ElementRef,
    private router: Router,
    public translate: TranslateService,
    private _isNgoOnChecker: IsNgoService,
    private _eref: ElementRef
  ) {
    translate.addLangs(AssetsConfig.languages);
    translate.setDefaultLang('en');
    _isNgoOnChecker.changeEmitted$.subscribe(isNgoPageOn => {
      this.isNgoPageOn = isNgoPageOn;
      this.facebookHref = isNgoPageOn ? 'https://facebook.com/globalmindaps' : 'https://facebook.com/GlobalMind-107332961950319';
      this.instagramHref = isNgoPageOn ? 'https://instagram.com/globalmindaps' : 'https://instagram.com/globalmind71';
      this.aboutUsHref = isNgoPageOn ? 'ngo-about' : 'about';
      this.logoSrc = isNgoPageOn ? './assets/img/logo_ngo_background.png' : './assets/img/logo_light_blue.png';
    });
    this.sidebarVisible = false;
  }

  redirect(path: string): void {
    this.router.navigateByUrl(path).then(() => {});
    this.sidebarClose();
  }
  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target))
      this.sidebarClose();
   }

  ngOnInit() {
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    const lang = localStorage.getItem('language');
    if (lang) {
      this.setLanguage(lang)
    } else {
      this.currentLanguage = this.translate.defaultLang;
    }
  }

  checkPreferredAgainstSupportedLanguages() {
    navigator.languages.forEach(lang => {
      AssetsConfig.languages.forEach(function (supported: string) {
        if (lang.substring(0, 2) === supported) {
          return supported;
        }
      });
    });
    return 'en';
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];

    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);
    html.classList.add('nav-open');

    this.sidebarVisible = true;
  };

  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  };

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  };

  setLanguage(lang: string) {
    this.currentLanguage = lang;
    this.translate.use(lang);
    localStorage.setItem('language', lang)
  }
}
