import {Component, Input } from '@angular/core';

@Component({
  selector: 'gallery-slider',
  templateUrl: './gallery-slider.component.html',
  styleUrls: ['./gallery-slider.component.scss']
})

export class GallerySliderComponent{
  @Input() title?: string;
  @Input() imgNameArray: any;
  @Input() theme: string;
}
