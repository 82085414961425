import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IsNgoService } from 'app/services/is-ngo-service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
    constructor(
        private router: Router,
        private _isNgoChecker: IsNgoService) {
        this._isNgoChecker.emitChange(false);
    }

    ngOnInit() {
    }

    redirect(path): void {
        this.router.navigateByUrl(path).then(() => {
        });
    }
}
