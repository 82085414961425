import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { TranslateModule } from '@ngx-translate/core';
import { PrivacyComponent } from './privacy/privacy.component';
import { ContactComponent } from './contact/contact.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgoComponent } from './ngo/ngo.component';
import { NgoTripsComponent } from './ngo-trips/ngo-trips.component';
import { ProductsComponent } from './products/products.component';
import { InvestorsComponent } from './investors/investors.component';
import { ComponentsModule } from 'app/components/components.module';
import { NgoAboutComponent } from './ngo-about/ngo-about.component';
import {YouTubePlayerModule} from '@angular/youtube-player';
import { NgoErasmusComponent } from './ngo-erasmus/ngo-erasmus.component';
import { NgoClassesComponent } from './ngo-classes/ngo-classes.component';

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        NgbModule,
        TranslateModule,
        ComponentsModule,
        YouTubePlayerModule,
    ],
    declarations: [
        HomeComponent,
        AboutComponent,
        NgoAboutComponent,
        PrivacyComponent,
        ContactComponent,
        NgoComponent,
        NgoErasmusComponent,
        NgoClassesComponent,
        NgoTripsComponent,
        ProductsComponent,
        InvestorsComponent
    ]
})
export class PagesModule { }
