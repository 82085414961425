import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IsNgoService } from 'app/services/is-ngo-service';

@Component({
    selector: 'app-ngo',
    templateUrl: './ngo.component.html',
    styleUrls: ['./ngo.component.scss']
})
export class NgoComponent {
coursesInfo = []
lang : string;

  constructor(
    private router: Router,
    private _isNgoChecker: IsNgoService,
    public translate: TranslateService) 
    { 
      this.setCoursesInfo();
      this._isNgoChecker.emitChange(true);
      this.translate.onLangChange.subscribe(() => {
      this.setCoursesInfo();
      })
    }

  ngOnInit() {}

  redirect(path): void {
    this.router.navigateByUrl(path);
  }

  setCoursesInfo(){
    this.coursesInfo = [      
      { header: "ngo.accordion.header5", text: "ngo.accordion.text5", photo: "./assets/img/ngo/courses/adult-course.png"},
      { header: "ngo.accordion.header6", text: "ngo.accordion.text6", photo: "./assets/img/ngo/courses/summer-course.png"},
      { header: "ngo.accordion.header1", text: "ngo.accordion.text1", photo: "./assets/img/ngo/courses/london.png"},
      { header: "ngo.accordion.header2", text: "ngo.accordion.text2", photo: "./assets/img/ngo/courses/america.png"},
      { header: "ngo.accordion.header3", text: "ngo.accordion.text3", photo: "./assets/img/ngo/courses/italy.jpg"}
    ]
    if(this.translate.currentLang == "it"){
      this.coursesInfo.push(
        { header: "ngo.accordion.header4", text: "ngo.accordion.text4", photo: "./assets/img/ngo/courses/local.jpg", hasGallery: true}
      )
    }
  }
}
